@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap");

/* For white -> cblue text Define the gradient-text class with the gradient background */
.gradient-text {
  font-family: "Roboto Slab", serif;
  background-image: linear-gradient(to right, #ffffff, #5ba7c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
}

/* Fallback for browsers that don't support -webkit-background-clip */
@supports not (-webkit-background-clip: text) {
  .gradient-text {
    font-family: "Roboto Slab", serif;
    color: #5ba7c7;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.grid-container {
  display: grid;
  grid-template-rows: auto 1fr auto; /* Three rows with the middle row taking up remaining space */
  grid-template-columns: 1fr; /* All rows have one column */
  gap: 10px; /* Adjust the gap between grid items as needed */
}

/* Define styles for grid items within the grid container */
.grid-item {
  background: red;
}

.header-width-full {
  width: calc(100% - 256px);
}

.header-width-collapsed {
  width: calc(100% - 64px);
}

.key-insight * {
  color: #ffffff !important;
}

.key-insight ol {
  list-style-type: number;
  margin-left: 40px;
}

.key-insight ul {
  list-style-type: disc;
  margin-left: 40px;
}

.key-insight a {
  text-decoration: underline;
  text-decoration-color: #5ba7c7;
  color: #5ba7c7 !important;
}

.resources-page * {
  color: #ffffff !important;
}

.resources-page ol {
  list-style-type: number;
  margin-left: 40px;
}

.resources-page ul {
  list-style-type: disc;
  margin-left: 40px;
}

.resources-page a {
  text-decoration: underline;
}

.sp {
  width: 32px;
  height: 32px;
  clear: both;
  margin: 20px auto;
}

/* Spinner Circle Rotation */
.sp-circle {
  border: 4px rgba(0, 0, 0, 0.25) solid;
  border-top: 4px white solid;
  border-radius: 50%;
  -webkit-animation: spCircRot 0.9s infinite linear;
  animation: spCircRot 0.9s infinite linear;
}

@-webkit-keyframes spCircRot {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spCircRot {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* this class is being used for custom height to show footer without scroll */
.custom-height {
  min-height: calc(100vh - 85px);
}

.customOverFlowClass {
  display: flex;
  overflow-x: auto;
  /* width: calc(100vw - 305px); */
  width: 100%;
  /* column-gap: 10px; */
  position: relative;
}

/* This hides the scrollbar for Chrome, Safari and Opera */
.customOverFlowClass::-webkit-scrollbar {
  display: none;
}

.customOverFlowClass {
  -ms-overflow-style: none;
}

.customOverFlowClass {
  scrollbar-width: none;
}

.custom-white {
  filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(2%)
    hue-rotate(306deg) brightness(118%) contrast(100%);
}
.apexcharts-toolbar {
  z-index: initial !important;
}

.chat-screen-height {
  height: calc(100vh - 168px);
}

.mt-auto {
  margin-top: auto;
}

.coach-chat-section::-webkit-scrollbar {
  display: none;
}
.coach-chat-section {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.hide-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.shadow-custom {
  box-shadow: 0px 2px 9px 7px rgba(0, 0, 0, 1.5);
}
.loginPageSection {
  min-height: calc(100vh - 90px);
}
.react-ios-time-picker-popup {
  align-items: center !important;
}
.react-ios-time-picker-btn {
  color: #177ddc !important;
}
.react-ios-time-picker-cell-inner-hour:hover,
.react-ios-time-picker-cell-inner-minute:hover,
.react-ios-time-picker-cell-inner-hour-format:hover {
  background-color: #177ddc !important;
}

.react-ios-time-picker-transition {
  animation: fade-in 400ms ease-out !important;
}

.small-white-text {
  @apply text-sm font-light text-white text-opacity-70;
}

.custom-white-text {
  @apply text-white text-opacity-70;
}

.action-btn {
  cursor: pointer;
  border-radius: 5px;
  background-color: #2f60e4;
  padding: 5px;
  color: white;
  margin-left: 2px;
}

/* accordian */
/* Define the transition animation */
.accordion-content-enter {
  max-height: 0;
  opacity: 0;
}

.accordion-content-enter-active {
  max-height: 1000px;
  opacity: 1;
  transition:
    max-height 600ms ease-in-out,
    opacity 550ms ease-in-out;
}

.accordion-content-exit {
  max-height: 1000px;
  opacity: 1;
}

.accordion-content-exit-active {
  max-height: 0;
  transition: max-height 250ms ease-in-out;
}

/* Style the content wrapper */
.overflow-auto.hide-scrollbar {
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.overflow-auto.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.circular-progress-animation {
  transition: stroke-dashoffset 0.9s ease;
}

.custom-player-height {
  height: calc(100vh - 170px);
}

.player-height {
  height: 100vh;
}

/* Full Player Custom Height */

.full-player-container {
  height: calc(100vh - 65px);
  margin-top: 65px;
}
html.no-scroll,
body.no-scroll {
  overflow: hidden !important;
}

.full-player {
  height: calc(100vh - 65px);
}

.line-animation {
  position: relative;
  overflow: hidden;
}
.line-animation::after {
  content: "";
  position: absolute;

  left: 1px;
  width: 100%;
  height: 100%;

  border-color: rgb(167 212 155 / var(--tw-border-opacity));

  animation: line 3s;
}

@keyframes line {
  0% {
    transform: translateX(-100%); /* Change this to -100% */
  }
  100% {
    transform: translateX(0); /* Change this to 0 */
  }
}
.water-animation {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.water-animation::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(167 212 155 / var(--tw-border-opacity));
  background-size: 100% 200%;
  animation: water 3s;
}

@keyframes water {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Custom Full Height */

.full-height {
  height: 100vh;
}

@media (max-height: 580px) {
  .full-player-custom {
    padding-top: 15px !important;
  }
  .overflow-y-scroll-custom {
    overflow-y: scroll !important;
  }
}
.customized-chart .apexcharts-menu {
  background: #1e1e1e !important;
  padding: 0px !important;
  border: none !important;
  outline: none !important;
}

.customized-chart .apexcharts-menu .apexcharts-menu-item {
  color: #ffffff !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.customized-chart .apexcharts-menu .apexcharts-menu-item:hover {
  background: #484848 !important;
}

.customized-chart .apexcharts-menu .apexcharts-menu-item:first-child:hover {
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
}

.customized-chart .apexcharts-menu .apexcharts-menu-item:last-child:hover {
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

*::-webkit-scrollbar {
  background: black;
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background: #999;
  width: 4px;
}

/* * CONVERSION OF DIALOG INTO POPUP NOTIFICATION */
/* * Changes: Added Border, set height, width to max */
.login-confirm-dialog {
  position: fixed;
  border-radius: 0.1rem /* 4px */;
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;
  background-color: #1e1e1e;
  /*background-color: transparent; /* Black with 50% opacity */
  /* background-color: rgba(0, 0, 0, 0.40); /* Black with 50% opacity */
  /* backdrop-filter: blur(5px); */
  color: white;
  /* width: 100dvw; */
  width: max-content;
  /* height: 100dvh; */
  height: auto;
  @apply bottom-4 right-[3%] md:right-5;
  /* bottom: 1rem; */
  /* right: 0; */
}

.highlighted-border {
  border: 2px solid transparent;
  border-radius: 5px;
  padding:inherit;
  /* animation: 1.5s highlighted-border-animation infinite alternate; */
  border-color: #177ddc;
  box-sizing: border-box; /* This includes padding and border in the width and height */
  /* box-shadow: 1px 3px 12px #177ddc; */
}

@keyframes highlighted-border-animation {
  from {
    border-color: transparent;
  }
  to {
    border-color: #177ddc;
    /* box-shadow: 0px 0px 0px transparent; */
  }
}
.highlighted-border-daytime {
  border: 2.5px solid transparent;
  border-radius: 5px;
  padding:inherit;
  /* animation: 1.5s highlighted-border-animation-daytime infinite alternate; */
  border-color: #fab861;
  box-sizing: border-box; /* This includes padding and border in the width and height */
  /* box-shadow: 1px 3px 12px #177ddc; */
}

@keyframes highlighted-border-animation-daytime {
  from {
    border-color: transparent;
  }
  to {
    border-color: #fab861;
    /* box-shadow: 0px 0px 0px transparent; */
  }
}

* {
  scroll-behavior: smooth;
}