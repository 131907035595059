@media (max-width: 315px) {
  .message-box {
    width: 10rem;
  }
}

@media (min-width: 316px) {
  .message-box {
    width: 12rem;
  }
}

@media (min-width: 361px) {
  .message-box {
    width: 15rem;
  }
}

@media (min-width: 401px) {
  .message-box {
    width: 18rem;
  }
}
