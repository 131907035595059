.lesson-content html {
  box-sizing: border-box;
  font-size: 16px;
}

.lesson-content *,
.lesson-content *:before,
.lesson-content *:after {
  box-sizing: inherit;
}

.lesson-content body,
.lesson-content h1,
.lesson-content h2,
.lesson-content h3,
.lesson-content h4,
.lesson-content h5,
.lesson-content h6,
.lesson-content p,
.lesson-content ol,
.lesson-content ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.lesson-content ol,
.lesson-content ul {
  list-style: none;
}

.lesson-content img {
  max-width: 100%;
  height: auto;
}

/*
Our styles
*/

.lesson-content * {
  font-family: "Helvetica";
  line-height: 25px;
  color: #f2f2f2;
  font-size: 19px;
}

/* Container element margin/padding */
.lesson-content h1,
.lesson-content h2,
.lesson-content h3,
.lesson-content h4,
.lesson-content h5,
.lesson-content h6,
.lesson-content p,
.lesson-content ul,
.lesson-content ol,
.lesson-content div {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.lesson-content ol,
.lesson-content ul {
  list-style-position: outside;
  list-style-type: circle;
  margin-left: 0;
  padding-left: 30px;
}

.lesson-content h1 {
  font-size: 30px;
  line-height: 50px;
}

.lesson-content h2 {
  font-size: 25px;
  line-height: 45px;
}

.lesson-content h3 {
  font-size: 20px;
  line-height: 40px;
}

.lesson-content img {
  min-width: 100%;
  max-width: 100%;
}

.lesson-content .dimg {
  margin: 0;
  min-width: 100%;
  max-width: 100%;
}

.lesson-content .blueBtn {
  display: block;
  text-decoration: none;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #5ba7c7;
  border-radius: 10px;
  width: 75%;
}

.lesson-content .blueBtn a {
}
